<template>
    <div>
        <div class="app">
            <div class="goods">
                <div class="home">
                    <span @click="home">首页</span><i class="el-icon-arrow-right" style="margin-top: 3px;"></i>
                    <span style="color: #999999;">全部二手车</span>
                </div>
            </div>
            <div class="table">
                <div class="goods" v-for="(goods,goodsIndex) in goodsList" :key="goodsIndex">
                    <ul class="type-list">
                        <div>
                            <el-row>
                                <el-col :span="2" class="type-list-title">
                                    <span class="type-list-title">{{ goods.title }}</span>
                                </el-col>
                                <el-col :span="20">
                                    <li class="type" v-for="(type,typeIndex) in goods.typeList" :key="typeIndex" @click="handleclick(typeIndex,goods,type,goodsIndex)">{{ type }}</li>
                                </el-col>
                                <el-col :span="2">
                                    <!-- <span>{{ goods.total }}</span> -->
                                    <!-- <span @click="changeadvanced">{{ advanced ? "收起" : "全部" }}</span>
                                    <i :class="advanced ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" />
                                    <div style="display: flex" class="range" v-show="advanced">
                                        <li class="type" v-for="(type,typeIndex) in goods.typeList" :key="typeIndex" @click="handleclick(typeIndex,goods,type,goodsIndex)">{{ type }}</li>
                                    </div> -->
                                </el-col>
                            </el-row>
                        </div>
                    </ul>
                </div>
                <div class="goods">
                    <ul class="type-list">
                        <div>
                            <el-row>
                                <el-col :span="2">
                                    <span class="type-list-title">更多</span>
                                </el-col>
                                <el-col :span="20">
                                    <div>
                                        <li class="type">
                                            <!-- <span>车龄</span> -->
                                            <el-select v-model="selectage" placeholder="车龄" style="width:110px">
                                                <el-option v-for="item in age" :key="item.value" :label="item.label" :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </li>
                                        <li class="type">
                                            <el-select v-model="selecttimes" placeholder="过户次数" style="width:110px">
                                                <el-option v-for="(item,index)  in times" :key="index" :label="item.label" :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </li>
                                        <li class="type">
                                            <el-select v-model="selectmileage" placeholder="里程" style="width:110px">
                                                <el-option v-for="(item,index)  in mileage" :key="index" :label="item.label" :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </li>
                                        <li class="type">
                                            <el-select v-model="selectdisplacement" placeholder="排量" style="width:110px">
                                                <el-option v-for="(item,index)  in displacement" :key="index" :label="item.label" :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </li>
                                        <li class="type">
                                            <el-select v-model="selectstandard" placeholder="排放标准" style="width:110px">
                                                <el-option v-for="(item,index)  in standard" :key="index" :label="item.label" :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </li>
                                        <li class="type">
                                            <el-select v-model="selectcolor" placeholder="车身颜色" style="width:110px">
                                                <el-option v-for="(item,index)  in color" :key="index" :label="item.label" :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </li>
                                        <li class="type">
                                            <el-select v-model="selectlocation" placeholder="车辆所在地" style="width:110px">
                                                <el-option v-for="(item,index)  in location" :key="index" :label="item.label" :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </li>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </ul>
                </div>
                <div class="goods">
                    <span style="margin: 15px 35px;">当前筛选</span>
                    <span class="no-goods" v-if="!showFiltergoods">暂时没有商品</span>
                    <ul class="type-list" v-else>
                        <li class="type" v-for="(goods,goodsKey) in filterList" :key="goodsKey">{{ goods }}
                            <span class="delete-goods" @click="deleteFilterGoods(goodsKey)">x</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="page-item1">
            <el-row>
                <el-col :span="14">
                    <span class="item">全部车源</span>
                </el-col>
                <el-col class="item-em" :span="10">
                    <span style="margin: 0 15px 15px 0;">智能排序</span>
                    <span style="margin: 0 15px 15px 0;">最新上架</span>
                    <span style="margin: 0 15px 15px 0;">价格最低</span>
                    <span style="margin: 0 15px 15px 0;">价格最高</span>
                    <span style="margin: 0 15px 15px 0;">车龄最短</span>
                    <span style="margin: 0 15px 15px 0;">里程最少</span>
                </el-col>
            </el-row>
            <div class="box" @click="details">
                <ul>
                    <li v-for="(v,index) in json.list" :key="index">
                        <div class="Single">
                            <img v-bind:src="v.src" alt="">
                            <h1 class="car">{{v.car}}</h1>
                            <h4 class="time">{{v.des}}</h4>
                            <p class="num">{{v.price}}</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="page">
                <el-pagination :page-size="16" :pager-count="11" layout="prev, pager, next" :total="800">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            advanced: false,

            selectCategory: [],
            json: {
                list: [
                    {
                        src: require("../../assets/tupian/WechatIMG927.png"),
                        car: "起亚K3 2013款 1.6L 自动DLX",
                        des: "2013年 | 8.50万公里",
                        price: "5.29万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG942.png"),
                        car: "马自达3星聘 2011款 1.6L 手动舒适型",
                        des: "2011年 | 10.00万公里",
                        price: "3.00万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG953.png"),
                        car: "Jeep 指南者 2015款 2.0L两驱豪华版",
                        des: "2015年 | 9.20万公里",
                        price: "9.28万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG966.png"),
                        car: "沃尔沃S60 2011款 2.0T 舒适版",
                        des: "2011年 | 13.12万公里",
                        price: "6.12万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG981.png"),
                        car: "奔驰GLA级 2016款 GLA200 动感型",
                        des: "2016年 | 14.28万公里",
                        price: "15.17万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG1000.png"),
                        car: "宝马3系 2017款 318i 时尚型",
                        des: "2017年 | 9.12万公里",
                        price: "16.29万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG1005.png"),
                        car: "大众 速腾 2007款 1.80T 自动舒适型",
                        des: "2007年 | 16.89万公里",
                        price: "1.60万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG1015.png"),
                        car: "大众 速腾 2009款 1.6L 手动舒适型",
                        des: "2009年 | 9.16万公里",
                        price: "1.80万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG1026.png"),
                        car: "奔驰E级 2015款 改款 E 260 L运动型",
                        des: "2015年 | 5.20万公里",
                        price: "20.67万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG1042.png"),
                        car: "Jeep 自由侠 2018款 互联大屏版 1.80T 自动高能版",
                        des: "2018年 | 2.56万公里",
                        price: "9.00万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG1055.png"),
                        car: "铃木 雨燕 2011款 1.3L 自动时尚型",
                        des: "2011年 | 4.90万公里",
                        price: "3.00万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG1067.png"),
                        car: "本田 思域 2009款 1.8L 自动经典版",
                        des: "2009年 | 12.89万公里",
                        price: "5.12万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG1079.png"),
                        car: "别克 凯越 2015款 1.5L自动经典型",
                        des: "2015年 | 3.29万公里",
                        price: "4.90万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG1090.png"),
                        car: "宝马3系 2017款 320Li M 运动型",
                        des: "2017年 | 8.16万公里",
                        price: "20.00万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG1102.png"),
                        car: "丰田 凯美瑞 2012款 2.5G 豪华导航版",
                        des: "2012年 | 16.89万公里",
                        price: "11.78万",
                    },
                    {
                        src: require("../../assets/tupian/WechatIMG1114.png"),
                        car: "雷克萨斯 IS 2009款 300",
                        des: "2009年 | 13.24万公里",
                        price: "9.56万",
                    },
                ],
            },
            //车龄
            age: [
                { value: "0", label: "不限" },
                { value: 1, label: "0%--20%" },
                { value: 2, label: "20%--40%" },
                { value: 3, label: "40%--60%" },
                { value: 4, label: "60%--80%" },
                { value: 5, label: "80%--100%" },
            ],
            //次数
            times: [
                {
                    value: "0",
                    label: "0次",
                },
                {
                    value: 1,
                    label: "1次",
                },
                {
                    value: 2,
                    label: "2-5次",
                },
                {
                    value: 3,
                    label: "5-8次",
                },
                {
                    value: 4,
                    label: "8次以上",
                },
            ],
            //里程
            mileage: [
                {
                    value: "0",
                    label: "1万公里以内",
                },
                {
                    value: 1,
                    label: "3万公里以内",
                },
                {
                    value: 2,
                    label: "5万公里以内",
                },
                {
                    value: 3,
                    label: "8万公里以内",
                },
                {
                    value: 4,
                    label: "10万公里以内",
                },
                {
                    value: 5,
                    label: "10万公里以上",
                },
            ],
            //排量
            displacement: [
                {
                    value: "0",
                    label: "1L以下",
                },
                {
                    value: 1,
                    label: "1.0-1.6L",
                },
                {
                    value: 2,
                    label: "1.6-2.0L",
                },
                {
                    value: 3,
                    label: "2.0-3.0L",
                },
                {
                    value: 4,
                    label: "3.0-4.0L",
                },
                {
                    value: 5,
                    label: "4L以上",
                },
            ],
            //排放标准
            standard: [
                {
                    value: "0",
                    label: "国一",
                },
                {
                    value: 1,
                    label: "国二",
                },
                {
                    value: 2,
                    label: "国三",
                },
                {
                    value: 3,
                    label: "国四",
                },
                {
                    value: 4,
                    label: "国五",
                },
                {
                    value: 5,
                    label: "国六",
                },
                {
                    value: 6,
                    label: "欧一",
                },
                {
                    value: 7,
                    label: "欧二",
                },
                {
                    value: 8,
                    label: "欧三",
                },
                {
                    value: 9,
                    label: "欧四",
                },
                {
                    value: 10,
                    label: "欧五",
                },
                {
                    value: 11,
                    label: "欧六",
                },
            ],
            //颜色
            color: [
                {
                    value: "0",
                    label: "黑色",
                },
                {
                    value: 1,
                    label: "白色",
                },
                {
                    value: 2,
                    label: "红色",
                },
                {
                    value: 3,
                    label: "橙色",
                },
                {
                    value: 4,
                    label: "金色",
                },
                {
                    value: 5,
                    label: "黄色",
                },
                {
                    value: 6,
                    label: "绿色",
                },
                {
                    value: 7,
                    label: "青色",
                },
                {
                    value: 8,
                    label: "蓝色",
                },
                {
                    value: 9,
                    label: "紫色",
                },
                {
                    value: 10,
                    label: "灰色",
                },
                {
                    value: 11,
                    label: "银色",
                },
                {
                    value: 12,
                    label: "米色",
                },
                {
                    value: 13,
                    label: "综色",
                },
                {
                    value: 14,
                    label: "其他",
                },
            ],
            //车辆所在地
            location: [
                {
                    value: "0",
                    label: "宁波",
                },
                {
                    value: 1,
                    label: "省外",
                },
            ],
            //绑定了筛选的选项，并用watch监听
            selectlocation: "",
            selectcolor: "",
            selectstandard: "",
            selectdisplacement: "",
            selecttimes: "",
            selectmileage: "",
            selectage: "",
            filterList: {},
            goodsList: [
                {
                    title: "品牌",
                    typeList: [
                        "不限",
                        "宝马",
                        "奥迪",
                        "奔驰",
                        "本田",
                        "丰田",
                        "大众",
                        "别克",
                        "福特",
                    ],
                    total: "全部",
                    id: 1,
                },
                {
                    title: "车系",
                    typeList: [
                        "不限",
                        "宝马3系",
                        "宝马5系",
                        "宝马X1",
                        "宝马1系",
                        "宝马X3",
                        "宝马X5",
                        "宝马7系",
                        "宝马2系旅行车",
                        "宝马4系",
                        "宝马3系GT",
                    ],
                    total: "全部",
                    id: 2,
                },
                {
                    title: "价格",
                    typeList: [
                        "不限",
                        "3万以内",
                        "3-5万",
                        "5-7万",
                        "7-9万",
                        "9-12万",
                        "12-16万",
                        "16-20万",
                        "20万以上",
                    ],
                    id: 3,
                },
            ],
            showFiltergoods: false,
        };
    },
    methods: {
        changeadvanced() {
            this.advanced = !this.advanced;
        },

        handleclick(typeIndex, goods, type, goodsIndex) {
            if (type === "全部") {
                return;
            }
            this.showFiltergoods = true;
            goods.index = typeIndex;
            // this.filterList.splice(goodsIndex,1,type);
            this.$set(this.filterList, goodsIndex, type);
        },
        deleteFilterGoods(goodsKey) {
            // this.filterList
            this.$delete(this.filterList, goodsKey);
            this.goodsList[goodsKey].index = 0;
            this.changeShowFilter();
        },
        changeShowFilter() {
            const filterListStr = JSON.stringfy(this.filterList);
            this.showFiltergoods = filterListStr !== "{}";
        },
        //点击详情挑转
        details() {
            this.$router.push("/cardetails");
        },
        home() {
            this.$router.push("/Homepage");
        },
    },
};
</script>

<style lang="less" scoped>
.table {
    width: 80%;
    margin: 0 auto;
    border: 1px solid rgb(230, 227, 227);
}
.hpage {
    width: 100%;
    height: 100%;
}
.goods {
    width: 100%;
    margin: 0 auto;
}
.goods ul {
    // border-top: 1px solid rgb(230, 227, 227);
    // border-left: 1px solid rgb(230, 227, 227);
    // border-right: 1px solid rgb(230, 227, 227);
    border-bottom: 1px solid rgb(230, 227, 227);
}
.goods li {
    list-style: none;
    display: inline-block;
    margin: 10px;
}
.page-item1 {
    width: 90%;
    margin: 0 auto;
    .item {
        height: 70px;
        line-height: 70px;
        font-size: 24px;
        padding-left: 60px;
    }
    .el-col {
        margin: 25px 0px;
    }
}
.time {
    font-size: 13px;
    color: #999;
}
.num {
    font-size: 18px;
    color: rgb(255, 0, 0);
}
.item-em {
    height: 70px;
    line-height: 70px;
    font-size: 14px;
    margin: 0 15px 15px 0;
    text-align: right;
}
.Single {
    display: inline-block;
    width: 250px;
    height: 285px;
    margin: 15px;
    cursor: pointer;
}
.box ul {
    display: flex;
    flex-wrap: wrap;
}
.box li {
    // padding: 3px;
    box-shadow: 1px 4px 5px 2px rgb(232, 232, 232);
    list-style: none;
    margin: 10px;
    // border: 1px solid #eee;
}
.box img {
    width: 100%;
    height: 168px;
}
.car {
    word-wrap: break-word;
    word-break: break-all;
    white-space: nowrap; /*强制span不换行*/
    display: inline-block; /*将span当做块级元素对待*/
    width: 100%; /*限制宽度 可设px*/
    overflow: hidden; /*超出宽度部分隐藏*/
    text-overflow: ellipsis; /*超出部分以点号代替*/
}
// .el-col {
//     margin: 25px 0px;
// }
.page {
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin: 0 auto;
}
.el-select:invalid {
    color: #000;
    background: #122742;
}
.el-option {
    color: #000;
    display: flex;
    // cursor: pointer;
    // z-index: 1000;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari 和 Chrome */
    appearance: none;
    .el-option:hover {
        color: #000;
    }
}
.home {
    width: 20%;
    margin: 20px 150px;
    cursor: pointer;
}
.delete-goods{
    cursor: pointer;
}
.type-list-title{
    height: 40px;
    line-height: 40px;
}
.el-col-2{
width: 5%;
}
</style>
